.captionedImage {
    position: relative;
    display: block;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
}

.captionedImage .imageCaption {
    position: absolute;
    display: block;
    color: white;
    background: black;
    padding: 3px 10px 5px 10px;
    font-size: 11pt;
    margin: 10px;
    right: 0;
    bottom: 0;
    line-height: normal;
}
