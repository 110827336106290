.buttons {
    margin: 30px auto 0 auto;
    width: fit-content;
    user-select: none;
    justify-content: center;
    flex-wrap: wrap;
    display: flex;
}

.projCard {
    height: 300px;
    box-sizing: border-box;
}

.projCard .imageContainer {
    width: 100%;
    height: 300px;
    background-size: cover;
    background-position-x: center;
    background-position-y: top;
    display: flex;
    align-items: flex-end;
}

.projCard .projHover {
    display: flex;
    align-items: flex-end;
    width: 100%;
    height: 300px;
    backdrop-filter: blur(5px);
    color: white;
}

.projCard h3 {
    margin: 0px;
    font-size: 21pt;
    text-align: right;
    margin-left: 20%;
    width: 100%;
    text-transform: uppercase;
    white-space: normal;
    padding: 6%;
    box-sizing: border-box;
    background: black;
    color: white;
}

@media only screen and (max-width: 898px) {
    #homeContainer {
        padding-top: 10vh;
    }
    .title > span {
        height: 16vw;
        width: 40vw;
        max-width: 350px;
        margin-bottom: 10px !important;
        margin-left: 10px !important;
    }
}

@media only screen and (max-width: 550px) {
    .title > span {
        display: block;
    }
    .buttons {
        margin-top: 30px;
    }
}

@media only screen and (max-height: 560px) and (max-width: 600px) {
    #homeContainer {
        padding-top: 0px;
    }
}

@media only screen and (max-height: 480px) {
    .title {
        font-size: 6em;
    }
}

@media only screen and (max-width: 400px) {
    .title > span {
        width: 70vw;
        height: 35vw;
    }
}
