.projWrapper {
    min-height: calc(100vh - 540px);
}

.title {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
}

.title > span {
    display: inline-block;
    background-repeat: no-repeat;
    height: 14vw;
    width: 40vw;
    max-width: 350px;
    max-height: 160px;
}

.title > span:first-child {
    margin-right: 2vw;
    background-position: right;
}

#sketchHolder {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: -1;
}

#homeContainer {
    top: 0px;
    padding-top: 25vh;
    box-sizing: border-box;
    width: 100%;
    height: 80vh;
    min-height: 400px;
}

#projectContainer {
    padding: 0px;
}

.button {
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
    color: #ffffff;
    font-family: sans-serif;
    padding: 11px 15px 13px 15px !important;
    border: #ffffff solid 1.5px;
    box-shadow: 0 0 1px 0px white inset, 0 0 1px 0px white;
    font-size: 14pt;
    margin: 0 5px 10px 5px !important;
    border-radius: 8px;
}

.button img {
    height: 16px;
    display: inline-block !important;
    vertical-align: middle;
    margin: 0px 10px 0px 0px !important;
}

.button:hover img {
    filter: invert(1);
}

.button:hover {
    background-color: #ffffff;
    color: #000000;
    padding: 11px 15px 13px 15px !important;
    margin: 0 5px 10px 5px !important;
}
