html {
    margin: 0;
    background-color: #000000;
    color: #fff;
    min-width: 200px;
}

body {
    margin: 0;
    min-height: 100vh;
    width: 100vw;
    overflow-x: hidden;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

footer {
    text-align: center;
    height: 120px;
    padding-top: 60px;
    padding-bottom: 60px;
    margin-top: 40px;
    font-size: 12pt;
    color: #999;
}

img {
    user-select: none;
}

a {
    user-select: none;
    color: inherit;
    color: #53a6ff;
    font-weight: bold;
    opacity: 1;
    text-decoration: none;
}

::-moz-selection {
    background: #0e6eff;
    color: white;
}
::selection {
    background: #0e6eff;
    color: white;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}
