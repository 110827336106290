.spinnerContainer {
    position: relative;
    height: 300px;
}

.spinner {
    transform-style: preserve-3d;
    transform-origin: 150px 150px 0;
    transition: 0.3s;
}

.spinner div {
    position: absolute;
    width: 100%;
    height: 300px;
}

.spinner .front {
    transform: translateZ(150px);
}

.spinner .bottom {
    transform: rotateX(-90deg) translateZ(150px);
    background: black;
}

@media (pointer: fine) {
    .spinner:hover {
        transform: rotateX(90deg);
    }
}
