p {
    line-height: 33px;
}

h1 {
    font-size: 30pt;
}

li {
    line-height: 33px;
    margin-bottom: 15px;
}

pre,
code {
    font-size: 14pt;
}

.projectLogo span {
    width: 120px;
    height: 50px;
    margin-right: 8px;
    background-repeat: no-repeat;
    display: inline-block;
}
.projectLogo {
    margin-left: -7px;
    margin-bottom: 20px;
    padding: 10px 3px 8px 9px;
    width: fit-content;
    display: inline-block;
}

.projectLogo:hover {
    background: white;
    display: inline-block;
}

.projectLogo:hover span {
    filter: invert();
}

.projButtons .button {
    width: fit-content;
    display: inline-block;
    margin-top: 15px;
}

.projWrapper {
    padding: 7vh 5vw;
    overflow-x: scroll;
    width: 90%;
    max-width: 800px;
    margin-right: auto;
    margin-left: auto;
}

.projMarkdown {
    overflow-x: scroll;
    width: 100%;
    margin: 20px 0px;
    font-size: 19px;
}

.projMarkdown h1 em {
    font-style: normal !important;
}
.projMarkdown h1 {
    font-size: 20pt;
    margin-top: 40px;
}
.projMarkdown h1:nth-child(1) {
    font-size: 30pt;
    margin-top: 20px;
}

.projButtons {
    float: right;
    height: fit-content;
}

.projWrapper img {
    display: block;
    margin: 10px auto 10px auto;
    max-width: 100%;
    max-height: 100vh;
}

.projScroller {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
}

.projScroller > .projectTiles {
    overflow-y: hidden;
    white-space: nowrap;
}
.projScroller > .projectTiles > .tile {
    display: inline-block;
    vertical-align: top;
    width: 28%;
    min-width: 300px;
}

@media only screen and (max-width: 700px) {
    .projButtons {
        width: 100%;
    }

    .projButtons .button {
        margin-top: 5px;
    }
}
