.container {
    width: 90%;
    max-width: 800px;
    margin: 100px auto;
    border: white;
}

.hello_title {
    font-size: 60pt;
    margin-bottom: 20px;
    margin-top: 2%;
}

.asciimap {
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    font-size: 12pt;
    position: relative;
    background: #000 !important;
}

.asciimap > .marker {
    position: absolute;
    top: 72%;
    left: 87%;
    background: #000;
    color: red;
    font-weight: bold;
    animation: blinker 1.4s step-start infinite;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

@media screen and (max-width: 1000px) {
    .asciimap {
        font-size: 1.7vw;
    }
}
@media screen and (max-width: 500px) {
    .asciimap {
        display: none;
    }
}

.jobContainer {
    padding: 6%;
    border-radius: 20px;
    margin-bottom: 120px;
    margin-top: 50px;
}

.jobContainer > .jobDate {
    margin: 0;
    font-size: 18pt;
    width: fit-content;
    margin-top: calc(-6% - 24px);
    margin-bottom: 20px;
    background: black;
    padding: 0 10px;
    margin-bottom: 4%;
}

.jobContainer > .jobTitle {
    margin: 8px 0;
    font-size: 20pt;
}

.jobContainer > .jobLocation {
    opacity: 0.5;
    margin: 0;
    font-size: 14pt;
}

table {
    margin-top: 10px;
}

tr {
    line-height: 1.8;
}

td.year {
    padding-right: 1vw;
    opacity: 1;
    font-weight: 800;
}

td {
    padding-top: 5px;
    opacity: 0.8;
    vertical-align: top;
}
